import React from "react"
import PropTypes from "prop-types"

export default function Button({ children }) {
  return (
    <button
      className="w-full font-oswald text-lg tablet:text-xl bg-gradient-to-b from-green-500 to-green-700
       border border-green-900 shadow-md shadow-gray-900/80 text-white rounded p-2"
    >
      {children}
    </button>
  )
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
}
