import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "../components/cards/postCard"
import Directory from "../components/directory/directory"

const RenderBody = ({ posts, socialCard, meta }) => (
  <>
    <Helmet
      title={"Articles"}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: `${meta.description}`,
        },
        {
          property: `og:title`,
          content: `${meta.title}`,
        },
        {
          property: `og:description`,
          content: `${meta.description}`,
        },
        {
          property: `og:url`,
          content: `${meta.url}/articles`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          name: `twitter:creator`,
          content: `${meta.author}`,
        },
        {
          name: `twitter:title`,
          content: `${meta.title}`,
        },
        {
          name: `twitter:description`,
          content: `${meta.description}`,
        },
      ].concat(meta)}
    />
    <div className="w-full py-24 px-8 desktop:px-0 flex justify-center items-center flex-col flex-wrap bg-stone-100">
      <div className="w-full tablet:w-3/4 desktop:w-4/5 4k:w-2/5">
        <div className="w-full flex items-baseline">
          <div className="w-full flex justify-start">
            <h1>Articles</h1>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 desktop:grid-cols-4 4k:grid-cols-4 gap-12">
          {posts.map((post, i) => (
            <PostCard
              key={i}
              category={post.node.category.title}
              categorySlug={post.node.category.slug}
              title={post.node.title}
              date={post.node.date}
              description={post.node.description}
              id={post.node.id}
              slug={post.node.slug}
              image={post.node.featuredImage.gatsbyImageData}
              author={post.node.author.name}
            />
          ))}
        </div>
      </div>
    </div>
  </>
)

const Articles = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const posts = data.allGraphCmsPost.edges
  const socialCard = data.file

  if (!posts) return null
  if (!meta) return null

  return (
    <Layout>
      <RenderBody posts={posts} meta={meta} socialCard={socialCard} />
      <Directory />
    </Layout>
  )
}

export default Articles

RenderBody.propTypes = {
  posts: PropTypes.array.isRequired,
  meta: PropTypes.array.isRequired,
}

export const query = graphql`
  query AllPosts {
    allGraphCmsPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          id
          date
          description
          category {
            title
            slug
            id
          }
          author {
            name
            id
          }
          featuredImage {
            gatsbyImageData
          }
        }
      }
    }
    file(name: {eq: "SocialCard-ArticlesPage"}) {
      id
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        image
        url
        siteUrl
      }
    }
  }
`
