import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Button from "../buttons/button"

const PostCard = ({
  category,
  categorySlug,
  image,
  title,
  description,
  id,
  slug,
}) => (
  <Link to={`/articles/${categorySlug}/${slug}`}>
    <div
      className="flex flex-row flex-wrap h-full bg-white rounded-t shadow-md hover:shadow-lg hover:shadow-gray-400/50 relative rounded border border-gray-300"
      key={id}
    >
      <div className="w-full h-48">
        <GatsbyImage
          image={image}
          className="w-full h-44 rounded-t bg-cover"
          alt={title}
        />
      </div>
      <div className="mx-4">
        <div className="w-auto mt-2 text-stone-500 font-roboto text-sm underline">
          {category}
        </div>
        <div className="w-full pb-2 -mt-1 text-stone-700 text-2xl font-oswald font-semibold tracking-wide">
          {title}
        </div>
      </div>
      <div className="mx-4 h-52">
        <div className="w-full text-stone-600 selection:bg-green-200 text-sm font-roboto">
          {description}
        </div>
      </div>
      <div className="w-full absolute bottom-0">
        <div className="px-4 pb-6">
          <Link to={`/articles/${categorySlug}/${slug}`}>
            <Button aria-label="continue reading">continue reading</Button>
          </Link>
        </div>
      </div>
    </div>
  </Link>
)

export default PostCard

PostCard.propTypes = {
  category: PropTypes.string.isRequired,
  categorySlug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}
