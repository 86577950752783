import React from "react"
import { Link } from "gatsby"

function Directory() {
  return (
    <div className="w-full phone:flex hidden tablet:flex flex-row flex-wrap justify-center phone:py-12 py-24 bg-gray-100 px-8 desktop:px-0 print:hidden">
      <div className="w-full desktop:w-4/5 flex justify-start">
        <div className="w-full">
          <h2 className="text-stone-700 font-semibold text-4xl font-oswald phone:text-center text-left">
            Directory
          </h2>
        </div>
      </div>

      <div className="w-full desktop:w-4/5 4k:w-2/5 flex flex-row flex-wrap justify-center pb-4">
        <div className="w-full grid grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-4 desktop:grid-cols-5 4k:grid-cols-5 tablet:gap-8 gap-8">
          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Coloring Books
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <Link to="/coloring-books/kids-art">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Kids Art
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/store/">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Buy Coloring Books
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/free-downloads/">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Free Downloads
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/coloring-books/meet-the-illustrator">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Meet the Illustrator (Lavinia Kirdani)
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Communication
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <Link to="/articles/communication/telepathy">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Telepathy
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/articles/communication/clair-abilities">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Clair Abilities
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/articles/communication/super-powers">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    &#34;Super Powers&#34;
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Experiencers Connect
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <Link to="/experiencers-connect/zoom-tutorials">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Zoom Tutorials
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                P&#39;nti
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <a
                  href="https://www.academia.edu/Documents/in/The_Pnti"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    The P&#39;nti - &#34;Gardeners of Intellect&#34;
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                QFS
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/rpeF4NLsQga8ObzWCwlu?_gl=1*v5elvd*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY2OTc3NzQwNy41LjEuMTY2OTc3Nzg3Mi42MC4wLjA.pdf"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    QFS Annotations
                  </div>
                </a>
              </li>
              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/J0XyCmx8TQGTtYA7mbmk?_gl=1*ywn67b*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MDQzNjA2Mi44LjEuMTY3MDQzNjE0Ni42MC4wLjA.pdf"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    The New Financial System
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/7alw0aEMQTyzGWYdtk8T?dl=true&_gl=1*6c303y*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MzMyNjkxMi4xMS4xLjE2NzMzMjcwOTguNjAuMC4w"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Revocable Living Trust
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/mQUkYmIfTVW38udLdPAZ?dl=true&_gl=1*1r7jf0a*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MzMyNjkxMi4xMS4xLjE2NzMzMjcyMTEuNTUuMC4w"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Durable Power of Attorney - Finance
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/fd7cj5YzQaqvhBh3BYJc?dl=true&_gl=1*mdqomu*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MzQ1MzUwOC4xMi4xLjE2NzM0NTM5OTkuNTguMC4w"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Appointment Exchange Questions
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/MSLHRxEyT0adBLxnTCLo?dl=true&_gl=1*18dkxap*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MzQ1MzUwOC4xMi4xLjE2NzM0NTQwMjQuMzMuMC4w"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Appointment Cheatsheet
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://media.graphassets.com/f5xCLgpYRMKqxR4WozrY?dl=true&_gl=1*57l699*_ga*MjExNTg1MzY0NC4xNjY4MDA2MzU4*_ga_G6FYGSYGZ4*MTY3MzQ1MzUwOC4xMi4xLjE2NzM0NTQwNjQuNjAuMC4w"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Appointment Checklist
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Resource Links
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500">
                <a
                  href="https://www.officialfirstcontact.com"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Official First Contact
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://www.youtube.com/channel/UCFJwX6NMbzTWRCmgV5Tc0lA"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    The Infinite Star Connections Podcast
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <a
                  href="https://www.youtube.com/channel/UCQcsw9S-yt8eqXbVfn_IWHg"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Close Encounters of the P&#39;ntl Kind
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500">
                <Link to="/archeoplanetography/apg-101">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Archeoplanetography (APG): 101
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Sasquatch
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500 py-1">
                <Link to="/sasquatch/the-sasquatch-message-to-humanity">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    The Sasquatch Message to Humanity
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                Ummo / Oummo
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500 py-1">
                <Link to="/ummo-oummo/message">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Message
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500 py-1">
                <Link to="/ummo-oummo/symbols">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Symbols
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green-500 py-1">
                <Link to="/ummo-oummo/craft-details">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Craft Details
                  </div>
                </Link>
              </li>

              <li className="hover:bg-green py-1">
                <a
                  href="https://media.graphcms.com/e5hwbZilRcUr7uMK6eTF"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    The UMMO Phonetic Language
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://media.graphcms.com/yoBRr9XR9OkQ29wLToAo"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Origin of the Letters
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://media.graphcms.com/Jnvq3D7jTLeHHVSHs4TE"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Study on Signatures
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://media.graphcms.com/0ozi34bQjmgD2wgJVA1R"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Complements on Semantics (1 of 3)
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://media.graphcms.com/xD0nEIuTYyfWocIKmJZ4"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Complements on Semantics (2 of 3)
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://media.graphcms.com/pUfUwH1RSDDGwD6iX7ab"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Complements on Semantics (3 of 3)
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://www.amazon.com/dp/1737187027/"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    UMMO Last Message
                  </div>
                </a>
              </li>
              <li className="hover:bg-green-500 py-1">
                <a href="https://media.graphcms.com/hasyoEaMQxGt6thWMVhN">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Ummo - A Functional Language
                  </div>
                </a>
              </li>
              <li className="hover:bg-green-500 py-1">
                <a href="https://media.graphassets.com/jes0XREYSXiY2xYOa3ho">
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Functional Theory of The GOONNIAOADOO BUUTZ Engine
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className="h-full py-4">
            <div className="w-full rounded-t drop-shadow-lg px-4 py-2 bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700">
              <div className="uppercase text-xl text-white font-semibold h-auto text-left font-oswald tracking-wide">
                ET Publications
              </div>
            </div>
            <ul className="h-full bg-blue-500 border border-blue-800 shadow-md hover:shadow-xl rounded-b divide-y divide-solid divide-blue-800">
              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://www.amazon.com/Enlightened-Chapters-Ms-Nancy-Tremaine/dp/B092L5XBQW/"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    Enlightened: Chapters of My Life
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://www.amazon.com/UMMO-Last-Message-2016-2019-ebook/dp/B0994VHHLX/"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    UMMO Last Message 2016 - 2019
                  </div>
                </a>
              </li>

              <li className="hover:bg-green-500 py-1">
                <a
                  href="https://www.amazon.com/dp/173718706X/"
                  external="true"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <div className="text-stone-100 hover:text-white rounded text-lg text-left px-4 font-quattro py-1">
                    UMMO: A Closer Look
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Directory
